import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'

import { ButtonStyled } from '../../../components/common/Button/styles'
import { useIntlLabel } from '../../../intl'
import { HomeInformationQuery } from './__generated__/HomeInformationQuery'

const HomeInformation = () => {
    const getIntlLabel = useIntlLabel()

    const LabelInput = styled.input`
        width: 100%;
        height: 140px;
        border-color: #ffffff;
    `

    const SectionStyled = styled.section((props) => {
        return {
            background: '#CFD8DC',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '46px 66px',
            gap: '10px',
            maxWidth: '1040px',
            height: '500px',
            margin: '0 auto',
            fontFamily: 'system-ui',
        }
    })

    const ChooseLabel = styled.p({
        //padding: 'px'
    })

    const { strapiGlobal } = useStaticQuery<HomeInformationQuery>(
        graphql`
            query HomeInformationQuery {
                strapiGlobal {
                    usedInformation
                    usedInformation_mg
                    helpInComment
                    helpInComment_mg
                    submitButton
                    submitButton_mg
                }
            }
        `,
    )

    return (
        <SectionStyled>
            <p>{getIntlLabel(strapiGlobal, 'usedInformation')}</p>
            <ChooseLabel>
                <input type="radio" value="oui" name="helpInformation" /> Oui
                <input type="radio" value="non" name="helpInformation" /> Non
            </ChooseLabel>

            <p>{getIntlLabel(strapiGlobal, 'helpInComment')}</p>

            <LabelInput></LabelInput>

            <ButtonStyled color="primary">
                {getIntlLabel(strapiGlobal, 'submitButton')}
            </ButtonStyled>
        </SectionStyled>
    )
}

export default HomeInformation
